import React, {useRef, useEffect} from "react";
import io from "socket.io-client";
import checkmark from "../check.png";

const Game = (props) => {
	
	const canvasRef = useRef();
	const context = useRef();
	
	const socket = useRef();
	const gameCode = useRef();
	
	const playerAmount = useRef(0);
	
	const quiz = useRef();
	
	const questions = useRef([]);
	
	const waiting = useRef(true);
	const timer = useRef(5);
	
	const scoreScreen = useRef(false);
	
	const actualQuestion = useRef();
	const question = useRef("");
	const answers = useRef(["", "", "", ""]);
	
	const whoAnsweredWhat = useRef([]);
	
	const players = useRef([]);
	
	const questionNum = useRef(0);
	
	const playerPoints = useRef({});
	
	const check = useRef(new Image());
	
	const playerRanks = useRef([]);
	
	const amountOfAnswers = useRef([0, 0, 0, 0]);
	
	const finishedScreen = useRef(false);
	
	const openTDBPressed = useRef(false);
	const usingOpenTDB = useRef(false);
	
	const onAStreak = useRef([]);
	
	const license = useRef();
	
	function animate()
	{
		requestAnimationFrame(animate);
		context.current.fillStyle = "#FFFFFF";
		context.current.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
		if(waiting.current)
		{
			context.current.fillStyle = "#000000";
			context.current.textAlign = "center";
			context.current.font = "500px Arial";
			var x = 500;
			for(; context.current.measureText("" + timer.current).width>canvasRef.current.width-100; x--,  context.current.font = x + "px Arial"){}
			context.current.fillText("" + timer.current, canvasRef.current.width/2,  canvasRef.current.height/2+(x*0.7)/2);
		}
		else if(scoreScreen.current)
		{
			context.current.fillStyle = "#000000";
			context.current.strokeStyle = "#000000";
			context.current.textAlign = "center";
			var x = 50;
			context.current.font = "50px Arial";
			context.current.fillStyle = "#000000";
			for(; context.current.measureText("First place is " + playerRanks.current[0][0] + " (" +  playerRanks.current[0][1][1] + " points)").width>canvasRef.current.width*0.5; x--,  context.current.font = x + "px Arial"){}
			context.current.textAlign = "left";
			context.current.fillText("First place is " + playerRanks.current[0][0] + " (" +  playerRanks.current[0][1][1] + " points)", 5,  canvasRef.current.height/3*2-canvasRef.current.height*0.07/2+(x*0.7)/2);
			context.current.textAlign = "center";
			context.current.beginPath();
			context.current.lineWidth = 3;
			context.current.rect(canvasRef.current.width*0.9-10, canvasRef.current.height/3*2-canvasRef.current.height*0.07-10, canvasRef.current.width*0.1, canvasRef.current.height*0.07);
			context.current.stroke();
			x = Math.floor(canvasRef.current.height*0.07)+1;
			context.current.font = x + "px Arial";
			for(; context.current.measureText("Next").width>canvasRef.current.width*0.1; x--,  context.current.font = x + "px Arial"){}
			context.current.fillText("Next", (canvasRef.current.width*0.9-10)+(canvasRef.current.width*0.1)/2, canvasRef.current.height/3*2-canvasRef.current.height*0.07-10+canvasRef.current.height*0.07/2+(x*0.7)/2);
			if(actualQuestion.current[1] === "true")
			{
				context.current.fillStyle = "#E21B3C";
			}
			else
			{
				context.current.fillStyle = "#E07081";
			}
			context.current.fillRect(0, canvasRef.current.height/3*2, canvasRef.current.width/2, canvasRef.current.height/6);
			if(actualQuestion.current[3] === "true")
			{
				context.current.fillStyle = "#1368CE";
			}
			else
			{
				context.current.fillStyle = "#8BA8CC";
			}
			context.current.fillRect(canvasRef.current.width/2, canvasRef.current.height/3*2, canvasRef.current.width/2, canvasRef.current.height/6);
			if(actualQuestion.current[5] === "true")
			{
				context.current.fillStyle = "#FFA602";
			}
			else
			{
				context.current.fillStyle = "#FFC966";
			}
			context.current.fillRect(0,  canvasRef.current.height/6*5, canvasRef.current.width/2, canvasRef.current.height/6);
			if(actualQuestion.current[7] === "true")
			{
				context.current.fillStyle = "#26890C";
			}
			else
			{
				context.current.fillStyle = "#73996A";
			}
			context.current.fillRect(canvasRef.current.width/2,  canvasRef.current.height/6*5, canvasRef.current.width/2, canvasRef.current.height/6);
			context.current.textAlign = "center";
			context.current.fillStyle = "#FFFFFF";
			if(actualQuestion.current[1] === "true")
			{
				x = 50;
				context.current.font = "50px Arial";
				for(; context.current.measureText(actualQuestion.current[2]).width>canvasRef.current.width/2*0.9-canvasRef.current.height/6; x--,  context.current.font = x + "px Arial"){}
				context.current.fillText(actualQuestion.current[2], (canvasRef.current.width/2-canvasRef.current.height/6)/2+canvasRef.current.width/2*0.05, canvasRef.current.height/12*9+x*(0.7/2));
				context.current.drawImage(check.current, canvasRef.current.width/2-canvasRef.current.height/6*0.85, canvasRef.current.height/12*9-canvasRef.current.height/6*0.7/2, canvasRef.current.height/6*0.7, canvasRef.current.height/6*0.7);
			}
			else
			{
				x = 50;
				context.current.font = "50px Arial";
				for(; context.current.measureText(actualQuestion.current[2]).width>canvasRef.current.width/2; x--,  context.current.font = x + "px Arial"){}
				context.current.fillText(actualQuestion.current[2], canvasRef.current.width/4, canvasRef.current.height/12*9+x*(0.7/2));
			}
			if(actualQuestion.current[3] === "true")
			{
				x = 50;
				context.current.font = "50px Arial";
				for(; context.current.measureText(actualQuestion.current[4]).width>canvasRef.current.width/2*0.9-canvasRef.current.height/6; x--,  context.current.font = x + "px Arial"){}
				context.current.fillText(actualQuestion.current[4], canvasRef.current.width/2 + (canvasRef.current.width/2-canvasRef.current.height/6)/2+canvasRef.current.width/2*0.05, canvasRef.current.height/12*9+x*(0.7/2));
				context.current.drawImage(check.current, canvasRef.current.width-canvasRef.current.height/6*0.85, canvasRef.current.height/12*9-canvasRef.current.height/6*0.7/2, canvasRef.current.height/6*0.7, canvasRef.current.height/6*0.7);
			}
			else
			{
				x = 50;
				context.current.font = "50px Arial";
				for(; context.current.measureText(actualQuestion.current[4]).width>canvasRef.current.width/2; x--,  context.current.font = x + "px Arial"){}
				context.current.fillText(actualQuestion.current[4], canvasRef.current.width/4*3, canvasRef.current.height/12*9+x*(0.7/2));
			}
			if(actualQuestion.current[5] === "true")
			{
				x = 50;
				context.current.font = "50px Arial";
				for(; context.current.measureText(actualQuestion.current[6]).width>canvasRef.current.width/2*0.9-canvasRef.current.height/6; x--,  context.current.font = x + "px Arial"){}
				context.current.fillText(actualQuestion.current[6], (canvasRef.current.width/2-canvasRef.current.height/6)/2+canvasRef.current.width/2*0.05, canvasRef.current.height/12*11+x*(0.7/2));
				context.current.drawImage(check.current, canvasRef.current.width/2-canvasRef.current.height/6*0.85, canvasRef.current.height/12*11-canvasRef.current.height/6*0.7/2, canvasRef.current.height/6*0.7, canvasRef.current.height/6*0.7);
			}
			else
			{
				x = 50;
				context.current.font = "50px Arial";
				for(; context.current.measureText(actualQuestion.current[6]).width>canvasRef.current.width/2; x--,  context.current.font = x + "px Arial"){}
				context.current.fillText(actualQuestion.current[6], canvasRef.current.width/4, canvasRef.current.height/12*11+x*(0.7/2));
			}
			if(actualQuestion.current[7] === "true")
			{
				x = 50;
				context.current.font = "50px Arial";
				for(; context.current.measureText(actualQuestion.current[8]).width>canvasRef.current.width/2*0.9-canvasRef.current.height/6; x--,  context.current.font = x + "px Arial"){}
				context.current.fillText(actualQuestion.current[8], canvasRef.current.width/2 + (canvasRef.current.width/2-canvasRef.current.height/6)/2+canvasRef.current.width/2*0.05, canvasRef.current.height/12*11+x*(0.7/2));
				context.current.drawImage(check.current, canvasRef.current.width-canvasRef.current.height/6*0.85, canvasRef.current.height/12*11-canvasRef.current.height/6*0.7/2, canvasRef.current.height/6*0.7, canvasRef.current.height/6*0.7);
			}
			else
			{
				x = 50;
				context.current.font = "50px Arial";
				for(; context.current.measureText(actualQuestion.current[8]).width>canvasRef.current.width/2; x--,  context.current.font = x + "px Arial"){}
				context.current.fillText(actualQuestion.current[8], canvasRef.current.width/4*3, canvasRef.current.height/12*11+x*(0.7/2));
			}
			let increase = canvasRef.current.height*0.3/whoAnsweredWhat.current.length;
			context.current.fillStyle = "#E21B3C";
			context.current.fillRect(canvasRef.current.width/2-canvasRef.current.width*0.275, canvasRef.current.height*0.5-canvasRef.current.height*0.05-increase*amountOfAnswers.current[0], canvasRef.current.width*0.1, canvasRef.current.height*0.05+increase*amountOfAnswers.current[0]); 
			x = Math.floor(canvasRef.current.height*0.12)+1;
			context.current.font = x + "px Arial";
			for(; context.current.measureText("" + amountOfAnswers.current[0]).width>canvasRef.current.width*0.1; x--,  context.current.font = x + "px Arial"){}
			context.current.fillText("" + amountOfAnswers.current[0], canvasRef.current.width/2-canvasRef.current.width*0.225, canvasRef.current.height*0.5+x*0.8);
			context.current.fillStyle = "#1368CE";
			context.current.fillRect(canvasRef.current.width/2-canvasRef.current.width*0.125, canvasRef.current.height*0.5-canvasRef.current.height*0.05-increase*amountOfAnswers.current[1], canvasRef.current.width*0.1, canvasRef.current.height*0.05+increase*amountOfAnswers.current[1]); 
			x = Math.floor(canvasRef.current.height*0.12)+1;
			context.current.font = x + "px Arial";
			for(; context.current.measureText("" + amountOfAnswers.current[1]).width>canvasRef.current.width*0.1; x--,  context.current.font = x + "px Arial"){}
			context.current.fillText("" + amountOfAnswers.current[1], canvasRef.current.width/2-canvasRef.current.width*0.075, canvasRef.current.height*0.5+x*0.8);
			context.current.fillStyle = "#FFA602";
			context.current.fillRect(canvasRef.current.width/2+canvasRef.current.width*0.025, canvasRef.current.height*0.5-canvasRef.current.height*0.05-increase*amountOfAnswers.current[2], canvasRef.current.width*0.1, canvasRef.current.height*0.05+increase*amountOfAnswers.current[2]); 
			x = Math.floor(canvasRef.current.height*0.12)+1;
			context.current.font = x + "px Arial";
			for(; context.current.measureText("" + amountOfAnswers.current[2]).width>canvasRef.current.width*0.1; x--,  context.current.font = x + "px Arial"){}
			context.current.fillText("" + amountOfAnswers.current[2], canvasRef.current.width/2+canvasRef.current.width*0.075, canvasRef.current.height*0.5+x*0.8);
			context.current.fillStyle = "#26890C";
			context.current.fillRect(canvasRef.current.width/2+canvasRef.current.width*0.175, canvasRef.current.height*0.5-canvasRef.current.height*0.05-increase*amountOfAnswers.current[3], canvasRef.current.width*0.1, canvasRef.current.height*0.05+increase*amountOfAnswers.current[3]); 
			x = Math.floor(canvasRef.current.height*0.12)+1;
			context.current.font = x + "px Arial";
			for(; context.current.measureText("" + amountOfAnswers.current[3]).width>canvasRef.current.width*0.1; x--,  context.current.font = x + "px Arial"){}
			context.current.fillText("" + amountOfAnswers.current[3], canvasRef.current.width/2+canvasRef.current.width*0.225, canvasRef.current.height*0.5+x*0.8);		
			context.current.font = "50px Arial";
			for(var x = 50; context.current.measureText(question.current).width>canvasRef.current.width-100; x--,  context.current.font = x + "px Arial"){} 
			context.current.fillStyle = "#000000";
			context.current.fillText(question.current, canvasRef.current.width/2, x+20);
			context.current.strokeStyle = "#000000";
			context.current.beginPath();
			context.current.arc(50, x+20-x*0.8/2, x/2+10, Math.PI/2, Math.PI/2*3, false);
			context.current.lineTo(canvasRef.current.width-50, x+20-x*0.8/2-(x/2+10));
			context.current.arc(canvasRef.current.width-50, x+20-x*0.8/2, x/2+10, Math.PI/2*3, Math.PI/2, false);
			context.current.lineTo(50, x+20-x*0.8/2+x/2+10);
			context.current.lineWidth = 3;
			context.current.stroke();
		}
		else if(finishedScreen.current)
		{
			context.current.fillStyle = "#000000";
			context.current.textAlign = "center";
			context.current.font = "100px Arial";
			var x = 100;
			for(; context.current.measureText("First place is " + playerRanks.current[0][0] + " (" + playerRanks.current[0][1][1] + " points)").width>canvasRef.current.width-100; x--,  context.current.font = x + "px Arial"){}
			context.current.fillText("First place is " + playerRanks.current[0][0] + " (" + playerRanks.current[0][1][1] + " points)", canvasRef.current.width/2,  canvasRef.current.height/2-110);
			if(playerRanks.current.length > 1)
			{
				context.current.font = "100px Arial";
				var x = 100;
				for(; context.current.measureText("Second place is " + playerRanks.current[1][0] + " (" + playerRanks.current[1][1][1] + " points)").width>canvasRef.current.width-100; x--,  context.current.font = x + "px Arial"){}
				context.current.fillText("Second place is " + playerRanks.current[1][0] + " (" + playerRanks.current[1][1][1] + " points)", canvasRef.current.width/2,  canvasRef.current.height/2);
			}
			if(playerRanks.current.length > 2)
			{
				context.current.font = "100px Arial";
				var x = 100;
				for(; context.current.measureText("Third place is " + playerRanks.current[2][0] + " (" + playerRanks.current[2][1][1] + " points)").width>canvasRef.current.width-100; x--,  context.current.font = x + "px Arial"){}
				context.current.fillText("Third place is " + playerRanks.current[2][0] + " (" + playerRanks.current[2][1][1] + " points)", canvasRef.current.width/2,  canvasRef.current.height/2+110);
			}
		}
		else
		{
			context.current.fillStyle = "#E21B3C";
			context.current.fillRect(0,  canvasRef.current.height/2, canvasRef.current.width/2, canvasRef.current.height/4);
			context.current.fillStyle = "#FFA602";
			context.current.fillRect(0,  canvasRef.current.height/4*3, canvasRef.current.width/2, canvasRef.current.height/4);
			context.current.fillStyle = "#1368CE";
			context.current.fillRect(canvasRef.current.width/2,  canvasRef.current.height/2, canvasRef.current.width/2, canvasRef.current.height/4);
			context.current.fillStyle = "#26890c";
			context.current.fillRect(canvasRef.current.width/2,  canvasRef.current.height/4*3, canvasRef.current.width/2, canvasRef.current.height/4);
			context.current.fillStyle = "#000000";
			context.current.textAlign = "center";
			context.current.font = "50px Arial";
			for(var x = 50; context.current.measureText(question.current).width>canvasRef.current.width-100; x--,  context.current.font = x + "px Arial"){} 
			context.current.fillText(question.current, canvasRef.current.width/2, x+20);
			context.current.font = "50px Arial";
			context.current.fillStyle = "#000000";
			context.current.strokeStyle = "#000000";
			context.current.beginPath();
			context.current.arc(50, x+20-x*0.8/2, x/2+10, Math.PI/2, Math.PI/2*3, false);
			context.current.lineTo(canvasRef.current.width-50, x+20-x*0.8/2-(x/2+10));
			context.current.arc(canvasRef.current.width-50, x+20-x*0.8/2, x/2+10, Math.PI/2*3, Math.PI/2, false);
			context.current.lineTo(50, x+20-x*0.8/2+x/2+10);
			context.current.lineWidth = 3;
			context.current.stroke();
			if(actualQuestion.current[10])
			{
				var maxWidth = canvasRef.current.width/4*3;
				var maxHeight = canvasRef.current.height/2-15-(x+20-x*0.8/2+x/2+25);
				var width = actualQuestion.current[10].width;
				var height = actualQuestion.current[10].height;
				if(width>height)
				{
					height = height/width*maxWidth;
					width = maxWidth;
				}
				else if(height>width)
				{
					width = width/height*maxHeight;
					height = maxHeight;
				}
				if(width>maxWidth)
				{
					height = height/width*maxWidth;
					width = maxWidth;
				}
				if(height>maxHeight)
				{
					width = width/height*maxHeight;
					height = maxHeight;
				}
				context.current.drawImage(actualQuestion.current[10], canvasRef.current.width/2-width/2, ((x+20-x*0.8/2+x/2+25)+(canvasRef.current.height/2-15-(x+20-x*0.8/2+x/2+25))/2)-height/2, width, height);
			}
			context.current.textAlign = "left";
			context.current.font = "60px Arial";
			context.current.fillText("Time: " + timer.current, 5, canvasRef.current.height/2-10);
			setText(answers.current[0], answers.current[1], answers.current[2], answers.current[3], context.current);
		}
	}
	
	function setText(a, b, c, d, context)
	{
		context.fillStyle = "#FFFFFF";
		context.textAlign = "center";
		var x = 50;
		context.font = "50px Arial";
		for(; context.measureText(a).width>canvasRef.current.width/2; x--,  context.font = x + "px Arial"){} 
		context.fillText(a, canvasRef.current.width/4, canvasRef.current.height/8*5+(x*0.8)/2);
		context.font = "50px Arial";
		for(x = 50; context.measureText(b).width>canvasRef.current.width/2; x--,  context.font = x + "px Arial"){} 
		context.fillText(b, canvasRef.current.width/4, canvasRef.current.height/8*7+(x*0.8)/2);
		context.font = "50px Arial";
		for(x = 50; context.measureText(c).width>canvasRef.current.width/2; x--,  context.font = x + "px Arial"){} 
		context.fillText(c, canvasRef.current.width/4*3, canvasRef.current.height/8*5+(x*0.8)/2);
		context.font = "50px Arial";
		for(x = 50; context.measureText(d).width>canvasRef.current.width/2; x--,  context.font = x + "px Arial"){} 
		context.fillText(d, canvasRef.current.width/4*3, canvasRef.current.height/8*7+(x*0.8)/2);
	}
	
	function getQuiz(onselect)
	{
		var file = document.createElement("input");
		file.setAttribute("type", "file");
		file.setAttribute("accept", ".quiz, .txt");
		file.addEventListener('change', () => {
			onselect(file.files[0]);
		});
		document.body.appendChild(file);
		file.click();
		file.remove();
	}
	
	function randomString(len)
	{
		var out = "";
		for(var x = 0; x<len; x++)
		{
			out+=String.fromCharCode(65+Math.floor(Math.random()*26));
		}
		return out;
	}
	
	useEffect(() => {
		canvasRef.current = document.getElementById("game");
		canvasRef.current.parentElement.style.margin = 0;
		canvasRef.current.parentElement.style.padding = 0;
		canvasRef.current.width = window.innerWidth;
		canvasRef.current.height = window.innerHeight;
		check.current.src = checkmark;
		context.current = canvasRef.current.getContext("2d");
		window.addEventListener("resize", () => {
			canvasRef.current.width = window.innerWidth;
			canvasRef.current.height = window.innerHeight;
		});
		document.getElementById("upload").onclick = () => {
			getQuiz(x => {
				quiz.current = x;
				var reader = new FileReader();
				reader.onload = y => {
					var f = y.target.result;
					var s = f.split("\n");
					var len = parseInt(s[0]);
					s.shift();
					for(var i = 0; i<len; i++)
					{
						var q = [];
						q.push(s[i*11]);
						q.push(s[i*11+1]);
						q.push(s[i*11+2]);
						q.push(s[i*11+3]);
						q.push(s[i*11+4]);
						q.push(s[i*11+5]);
						q.push(s[i*11+6]);
						q.push(s[i*11+7]);
						q.push(s[i*11+8]);
						q.push(parseInt(s[i*11+9]));
						var maybeImage = s[i*11+10];
						if(maybeImage != "undefined")
						{
							var img = new Image();
							img.src = maybeImage;
							q.push(img);
						}
						else
						{
							q.push(undefined);
						}
						questions.current.push(q);
					}
					
					if(len>0)
					{
						startGameStuff();
					}
				};
				reader.readAsText(x);
			});
		};
		document.getElementById("openTDB").onclick = () => {
			if(!openTDBPressed.current)
			{
				openTDBPressed.current = true;
				document.getElementById("openTDBStuff").hidden = false;
			}
		};
		const categoriesRequest = new XMLHttpRequest();
		categoriesRequest.open("GET", "https://opentdb.com/api_category.php");
		categoriesRequest.send();
		var categories = document.getElementById("category");
		categoriesRequest.onload = e => {
			var cats = JSON.parse(categoriesRequest.responseText);
			while(categories.firstChild)
			{
				categories.removeChild(categories.firstChild);
			}
			var option = document.createElement("option");
			option.innerHTML = "Any Category";
			option.value = "any";
			categories.appendChild(option);
			for(var i = 0; i<cats.trivia_categories.length; i++)
			{
				option = document.createElement("option");
				option.innerHTML = cats.trivia_categories[i].name;
				option.value = cats.trivia_categories[i].id;
				categories.appendChild(option);
			}
		};
		document.getElementById("Question amount").value = 10;
		document.getElementById("Time amount").value = 25;
		animate();
		return function cleanup() {
			socket.current.disconnect();
		}
	}, []);
	
	function getById(div, id)
	{
		var e = div.getElementsByTagName("*");
		for(var i = 0; i<e.length; i++)
		{
			if(e[i].id === id)
			{
				return e[i];
			}
		}
		return undefined;
	}
	
	function startGameStuff()
	{
		document.getElementById("upload").remove();
		document.getElementById("dumb break").remove();
		document.getElementById("openTDBStuff").remove();
		document.getElementById("openTDB").remove();
		license.current.remove();
		socket.current = io.connect("wss://server.jamesgoo.de:9500", {transports: ["websocket"], forceNew: true, reconnection: false});
		gameCode.current = randomString(6);
		socket.current.emit("start", gameCode.current);
		socket.current.on("started", z => {
			document.getElementById("joiners").insertAdjacentHTML("beforeend", "<h2 style='font-size:40px'>Game Code: " + gameCode.current + "</h2>");
			socket.current.on("joined", name => {
				try
				{
					document.getElementById("joiners").insertAdjacentHTML("beforeend", "<p style='font-size:25px' id='" + name + "'>" + name + "</p>");
				}
				catch
				{
					
				}
				players.current.push(name);
				playerAmount.current++;
			});
			socket.current.on("leave", name => {
				try
				{
					getById(document.getElementById("joiners"), name).remove();
				}
				catch{}
				try
				{
					if(whoAnsweredWhat.current.length > 0)
					{
						for(let n = 0; n<whoAnsweredWhat.current.length; n++)
						{
							if(whoAnsweredWhat.current[n][0] == name)
							{
								whoAnsweredWhat.current.splice(n, 1);
								break;
							}
						}
					}
				}
				catch{}
				try
				{
					players.current.splice(players.current.indexOf(name), 1);
					playerAmount.current--;
				}
				catch{}
			});
			socket.current.on("answer", ans => {
				whoAnsweredWhat.current.push(ans);
				if(whoAnsweredWhat.current.length == playerAmount.current)
				{
					questionOver();
				}
			});
		});
		socket.current.on("not_started", z => {
			gameCode.current = randomString(6);
			socket.current.emit("start", gameCode.current);
		});
		canvasRef.current.addEventListener("click", function(event) {
			if(scoreScreen.current)
			{
				context.current.rect(canvasRef.current.width*0.9-10, canvasRef.current.height/3*2-canvasRef.current.height*0.07-10, canvasRef.current.width*0.1, canvasRef.current.height*0.07);
				if(event.x < canvasRef.current.width-10 && event.x > canvasRef.current.width*0.9-10 && event.y < canvasRef.current.height/3*2-10 && event.y > canvasRef.current.height/3*2-canvasRef.current.height*0.07-10)
				{
					if(questions.current.length>0)
					{
						startQuestion();
						socket.current.emit("waiting", "");
					}
					else
					{
						scoreScreen.current = false;
						finishedScreen.current = true;
						socket.current.emit("finished", "");
					}
				}
			}
		});
		document.getElementById("startGame").hidden = false;
	}
	
	function ordinal(n) {
		var s = ["th", "st", "nd", "rd"];
		var v = n%100;
		return n + (s[(v-20)%10] || s[v] || s[0]);
	}
	
	function startGame()
	{
		if(playerAmount.current>0)
		{
			document.body.style.overflow = "hidden";
			socket.current.emit("start_game", "");
			document.getElementById("joiners").remove();
			document.getElementById("startGameArea").remove();
			document.getElementById("game").hidden = false;
			startQuestion();
		}
	}
	
	function countdownTimer(len, num)
	{
		timer.current = len;
		if(len> 0 && !scoreScreen.current && !waiting.current && questionNum.current-1 == num)
		{
			setTimeout(() => {
				countdownTimer(len-1, num);
			}, 1000);
		}
		else if(!scoreScreen.current && !waiting.current && questionNum.current-1 == num)
		{
			questionOver();
		}
	}
	
	function questionOver()
	{
		var whoGetsWhat = [];
		for(var i = 0; i<players.current.length; i++)
		{
			var you = undefined;
			for(var t = 0; t<whoAnsweredWhat.current.length; t++)
			{
				if(whoAnsweredWhat.current[t][0] == players.current[i])
				{
					you = whoAnsweredWhat.current[t];
				}
			}
			if(you)
			{
				console.log(actualQuestion.current[1+you[1][1]*2]);
				if(actualQuestion.current[1+you[1][1]*2]=="true")
				{
					let streakAmount = streak(players.current[i], true);
					if(playerPoints.current[players.current[i]])
					{
						playerPoints.current[players.current[i]] += Math.floor(you[1][0]*((streakAmount-1)*0.15+1));
					}
					else
					{
						playerPoints.current[players.current[i]] = Math.floor(you[1][0]*((streakAmount-1)*0.15+1));
					}
					whoGetsWhat.push([players.current[i], [Math.floor(you[1][0]*((streakAmount-1)*0.15+1)), playerPoints.current[players.current[i]], streakAmount]]);
				}
				else
				{
					streak(players.current[i], false);
					if(playerPoints.current[players.current[i]])
					{
						//for now nothing
					}
					else
					{
						playerPoints.current[players.current[i]] = 0;
					}
					whoGetsWhat.push([players.current[i], [0, playerPoints.current[players.current[i]], 0]]);
				}
			}
			else
			{
				streak(players.current[i], false);
				if(playerPoints.current[players.current[i]])
				{
					//for now nothing
				}
				else
				{
					playerPoints.current[players.current[i]] = 0;
				}
				whoGetsWhat.push([players.current[i], [0, playerPoints.current[players.current[i]], 0]]);
			}
		}
		var order = [...whoGetsWhat];
		order.sort((e1, e2) => {
			return e2[1][1]-e1[1][1];
		});
		playerRanks.current = order;
		for(var orderI = 0; orderI<order.length; orderI++)
		{
			for(var whoI = 0; whoI<whoGetsWhat.length; whoI++)
			{
				if(order[orderI][0] === whoGetsWhat[whoI][0])
				{
					whoGetsWhat[whoI][1].push(ordinal(orderI+1));
					if(orderI == 0)
					{
						if(order.length>1)
						{
							whoGetsWhat[whoI][1].push(whoGetsWhat[whoI][1][1]-order[1][1][1]);
							whoGetsWhat[whoI][1].push(order[1][0]);
						}
						else
						{
							whoGetsWhat[whoI][1].push("none");
						}
					}
					else
					{
						whoGetsWhat[whoI][1].push(order[orderI-1][1][1]-whoGetsWhat[whoI][1][1]);
						whoGetsWhat[whoI][1].push(order[orderI-1][0]);
					}
				}
			}
		}
		amountOfAnswers.current = [0, 0, 0, 0];
		if(whoAnsweredWhat.current.length > 0)
		{
			for(var i = 0; i<whoAnsweredWhat.current.length; i++)
			{
				amountOfAnswers.current[whoAnsweredWhat.current[i][1][1]]++;
			}
		}
		socket.current.emit("end_question", whoGetsWhat);
		scoreScreen.current = true;
	}
	
	function startQuestion()
	{
		scoreScreen.current = false;
		waiting.current = true;
		timer.current = 5;
		setTimeout(() => {
			timer.current = 4;
			setTimeout(() => {
				timer.current = 3;
				setTimeout(() => {
					timer.current = 2;
					setTimeout(() => {
						timer.current = 1;
						setTimeout(() => {
							waiting.current = false;
							actualQuestion.current = questions.current.shift();
							question.current = actualQuestion.current[0];
							answers.current[0] = actualQuestion.current[2];
							answers.current[1] = actualQuestion.current[6];
							answers.current[2] = actualQuestion.current[4];
							answers.current[3] = actualQuestion.current[8];
							whoAnsweredWhat.current = [];
							socket.current.emit("question", [question.current, answers.current, actualQuestion.current[9]]);
							questionNum.current++;
							countdownTimer(actualQuestion.current[9], questionNum.current-1);
						}, 1000);
					}, 1000);
				}, 1000);
			}, 1000);
		}, 1000);
	}
	
	const style = {
		position: "relative",
		overflow: "hidden",
		bottom: "0"
	};
	
	function startOpenTDB()
	{
		var requestLink = "https://opentdb.com/api.php?amount=";
		requestLink+=document.getElementById("Question amount").value;
		if(document.getElementById("category").value != "any")
		{
			requestLink+="&category="+document.getElementById("category").value;
		}
		if(document.getElementById("difficulty").value != "any")
		{
			requestLink+="&difficulty="+document.getElementById("difficulty").value;
		}
		if(document.getElementById("difficulty").value != "any")
		{
			requestLink+="&difficulty="+document.getElementById("difficulty").value;
		}
		requestLink+="&type=multiple";
		const request = new XMLHttpRequest();
		request.open("GET", requestLink);
		request.send();
		request.onload = e => {
			var qs = JSON.parse(request.responseText);
			if(qs.response_code == 1)
			{
				document.getElementById("errorDisplay").innerHTML = "Invalid question amount (Try reducing the question amount)";
			}
			else if(qs.response_code == 0)
			{
				for(var i = 0; i<qs.results.length; i++)
				{
					var q = [];
					q.push(decode(qs.results[i].question));
					var answers = [];
					answers.push(["true", decode(qs.results[i].correct_answer)]);
					answers.push(["false", decode(qs.results[i].incorrect_answers[0])]);
					answers.push(["false", decode(qs.results[i].incorrect_answers[1])]);
					answers.push(["false", decode(qs.results[i].incorrect_answers[2])]);
					answers = answers.sort(() => Math.random() - 0.5);
					q.push(answers[0][0]);
					q.push(answers[0][1]);
					q.push(answers[1][0]);
					q.push(answers[1][1]);
					q.push(answers[2][0]);
					q.push(answers[2][1]);
					q.push(answers[3][0]);
					q.push(answers[3][1]);
					q.push(parseInt("" + document.getElementById("Time amount").value));
					q.push(undefined);
					questions.current.push(q);
				}
				startGameStuff();
			}
			console.log(qs);
		};
	}
	
	function decode(html) {
		var e = document.createElement("textarea");
		e.innerHTML = html;
		return e.value;
	}
	
	function streak(name, correct)
	{
		var i = -1;
		if(onAStreak.current.length > 0)
		{
			for(let x = 0; x<onAStreak.current.length; x++)
			{
				if(onAStreak.current[x][0] == name)
				{
					i = x;
					break;
				}
			}
		}
		if(correct)
		{
			if(i == -1)
			{
				onAStreak.current.push([name, 1]);
				return 1;
			}
			else
			{
				onAStreak.current[i][1]++;
				return onAStreak.current[i][1];
			}
		}
		else
		{
			if(i != -1)
			{
				onAStreak.current.splice(i, 1);
			}
			return 0;
		}
	}
	
	return (
		<div>
			<div style={{margin: 0}}>
				<div id="joiners"/>
				<div id="startGameArea">
					<button id="startGame" onClick={startGame} style={{"font-size":"30px"}} hidden={true}>Start Game</button>
				</div>
				<button id="upload" style={{"font-size":"30px"}}>Upload quiz</button>
				<br id="dumb break"/>
				<button id="openTDB" style={{"font-size":"30px"}}>Random quiz</button>
				<div id="openTDBStuff"  hidden={true}>
					<h2 style={{"font-size":"25px", "line-height":"0.5"}}>Category</h2>
					<select id="category"/>
					<h2 style={{"font-size":"25px", "line-height":"0.5"}}>Difficulty</h2>
					<select id="difficulty">
						<option value="any">Any Difficulty</option>
						<option value="easy">Easy</option>
						<option value="medium">Medium</option>
						<option value="hard">Hard</option>
					</select>
					<h2 style={{"font-size":"25px", "line-height":"0.5"}}>Question amount</h2>
					<input type="number" id="Question amount"/>
					<h2 style={{"font-size":"25px", "line-height":"0.5"}}>Time per question</h2>
					<input type="number" id="Time amount"/>
					<p/>
					<button id="startOpenTDB" onClick={startOpenTDB} style={{"font-size":"30px"}}>Start With Random Game</button>
					<p id="errorDisplay"/>
					<div style={{"position":"relative", "margin":"auto", "width":"50%"}}>
						<p style={{"text-align":"center"}}>Random questions come from the API provided by Open Trivia Database under the Creative Commons Attribuation-ShareAlike 4.0 International License</p>
						<a href="https://opentdb.com/">Open Trivia Database</a>
						<br/>
						<a href="https://creativecommons.org/licenses/by-sa/4.0/">Creative Commons Attribuation-ShareAlike 4.0 International License</a>
					</div>
				</div>
				<canvas id="game" style={{style}} hidden={true}/>
			</div>
			<a style={{"position": "absolute", "bottom": "2%", "transform": "translate(-50%, 0)"}} href="https://creativecommons.org/licenses/by-sa/4.0/" ref={license}>
				<img src="https://licensebuttons.net/l/by-sa/4.0/88x31.png"/>
			</a>
		</div>
	);
};

export default Game;