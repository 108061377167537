import React, {useRef, useEffect} from "react";

const Create = (props) => {
	
	const questions = useRef([]);
	const questionAmount = useRef(0);
	
	useEffect(() => {
		addQuestion();
	}, []);
	
	function download(filename, text) {
		var element = document.createElement('a');
		element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
		element.setAttribute('download', filename);
		element.style.display = 'none';
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	}
	
	function removeQuestion(question)
	{
		document.getElementById("question" + question).remove();
		questions.current.splice(questions.current.indexOf(questions.current.find(x => {return x.id == "question" + question;})), 1);
		var q = document.getElementById("questions").getElementsByTagName("div");
		for(var i = 0; i<q.length; i++)
		{
			q[i].getElementsByTagName("h2")[0].innerHTML = "Question " + (i+1);
		}
	}
	
	function addQuestion()
	{
		document.getElementById("questions").insertAdjacentHTML("beforeend", '<div id="question' + (questionAmount.current+1) + '"><h2>Question ' + (questions.current.length+1) + '</h2 id="title"><span>Question: </span><input type="text" autoComplete="off" id="question"/><button id="remove' + (questionAmount.current+1) + '">Remove Question</button><p/><image id="display"/><span>Answer: </span><input type="checkbox" id="c1"/><input type="text" autoComplete="off" id="a1"/><span> Answer: </span><input type="checkbox" id="c2"/><input type="text" autoComplete="off" id="a2"/><p/><span>Answer: </span><input type="checkbox" id="c3"/><input type="text" autoComplete="off" id="a3"/><span> Answer: </span><input type="checkbox" id="c4"/><input type="text" autoComplete="off" id="a4"/><p/><span>Time: </span><input type="number" step="1" min="1" max="120" value="30" oninput="validity.valid||(value=30)" id="time"/><p/><input type="file" id="image" accept="image/*" hidden="true"/><button id="imageButton">Add Image</button><p/></div>');
		var q = questionAmount.current+1;
		document.getElementById("remove" + (questionAmount.current+1)).onclick = () => {removeQuestion(q);};
		questions.current.push(document.getElementById("question" + (questionAmount.current+1)));
		getById(document.getElementById("question" + (questionAmount.current+1)), "imageButton").onclick = () => {
			getById(document.getElementById("question" + q), "image").click();
		};
		getById(document.getElementById("question" + (questionAmount.current+1)), "image").addEventListener("change", function () {
			if(this.files && this.files[0])
			{
				getDataUrl(URL.createObjectURL(this.files[0]), (result) => {
					getById(document.getElementById("question" + q), "display").src = result;
					getById(document.getElementById("question" + q), "display").parentNode.insertBefore(document.createElement("p"), getById(document.getElementById("question" + q), "display").nextSibling);
					getById(document.getElementById("question" + q), "display")["fullFile"] = this.files[0];
				});
			}
		});
		questionAmount.current++;
	}
	
	function getById(div, id)
	{
		var e = div.getElementsByTagName("*");
		for(var i = 0; i<e.length; i++)
		{
			if(e[i].id === id)
			{
				return e[i];
			}
		}
		return undefined;
	}
	
	function getDataUrl(src, onFinish) {
		var img = new Image();
		img.crossOrigin = "Anonymous";
		img.onload = () => {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			if(img.height>200)
			{
				canvas.height = 200;
				canvas.width = img.width/img.height*200;
			}
			else if(img.width>img.height)
			{
				canvas.width = 300;
				canvas.height = img.height/img.width*300;
			}
			else
			{
				canvas.height = 200;
				canvas.width = img.width/img.height*200;
			}
			ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
			onFinish(canvas.toDataURL('image/jpg'));
		}
		img.src = src;
	}
	
	function getDataUrlNormal(src, onFinish) {
		var img = new Image();
		img.crossOrigin = "Anonymous";
		img.onload = () => {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			canvas.width = img.width;
			canvas.height = img.height;
			ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
			onFinish(canvas.toDataURL('image/jpg'));
		}
		img.src = src;
	}
	
	function downloadQuiz()
	{
		var q = document.getElementById("questions").getElementsByTagName("div");
		var quiz = q.length + "\n";
		if(q.length>0)
		{
			var i = 0;
			quiz += getById(q[i], "question").value + "\n";
			quiz += getById(q[i], "c1").checked + "\n";
			quiz += getById(q[i], "a1").value + "\n";
			quiz += getById(q[i], "c2").checked + "\n";
			quiz += getById(q[i], "a2").value + "\n";
			quiz += getById(q[i], "c3").checked + "\n";
			quiz += getById(q[i], "a3").value + "\n";
			quiz += getById(q[i], "c4").checked + "\n";
			quiz += getById(q[i], "a4").value + "\n";
			if(getById(q[i], "time").value!="")
			{
				quiz += getById(q[i], "time").value + "\n";
			}
			else
			{
				quiz += 30 + "\n";
			}
			var go = x => {
				quiz += x + "\n";
				i++;
				if(i<q.length)
				{
					quiz += getById(q[i], "question").value + "\n";
					quiz += getById(q[i], "c1").checked + "\n";
					quiz += getById(q[i], "a1").value + "\n";
					quiz += getById(q[i], "c2").checked + "\n";
					quiz += getById(q[i], "a2").value + "\n";
					quiz += getById(q[i], "c3").checked + "\n";
					quiz += getById(q[i], "a3").value + "\n";
					quiz += getById(q[i], "c4").checked + "\n";
					quiz += getById(q[i], "a4").value + "\n";
					if(getById(q[i], "time").value!="")
					{
						quiz += getById(q[i], "time").value + "\n";
					}
					else
					{
						quiz += 30 + "\n";
					}
					if(getById(q[i], "display")["fullFile"])
					{
						getDataUrlNormal(URL.createObjectURL(getById(q[i], "display")["fullFile"]), go);
					}
					else
					{
						go(undefined);
					}
				}
				else
				{
					download("questions.quiz", quiz);
				}
			};
			if(getById(q[i], "display")["fullFile"])
			{
				getDataUrlNormal(URL.createObjectURL(getById(q[i], "display")["fullFile"]), go);
			}
			else
			{
				go(undefined);
			}
		}
		//for(var i = 0; i<q.length; i++)
		//{
		//	quiz += getById(q[i], "question").value + "\n";
		//	quiz += getById(q[i], "c1").checked + "\n";
		//	quiz += getById(q[i], "a1").value + "\n";
		//	quiz += getById(q[i], "c2").checked + "\n";
		//	quiz += getById(q[i], "a2").value + "\n";
		//	quiz += getById(q[i], "c3").checked + "\n";
		//	quiz += getById(q[i], "a3").value + "\n";
		//	quiz += getById(q[i], "c4").checked + "\n";
		//	quiz += getById(q[i], "a4").value + "\n";
		//	
		//}
	}
	
	return (
		<div>
			<div>
				<div id="questions">
				</div>
				<button onClick={addQuestion}>Add Question</button>
				<br/>
				<button onClick={downloadQuiz}>Download Quiz</button>
			</div>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<a style={{"position": "fixed", "bottom": "2%", "transform": "translate(-50%, 0)"}} href="https://creativecommons.org/licenses/by-sa/4.0/">
				<img src="https://licensebuttons.net/l/by-sa/4.0/88x31.png"/>
			</a>
		</div>
	);
};

export default Create;