import React, {useRef, useEffect} from "react";
import io from "socket.io-client";
import notkahoot from '../notkahoot.png';

const Play = (props) => {
	
	const canvasRef = useRef();
	const context = useRef();
	const middleDiv = useRef();
	
	const socket = useRef();
	
	const joining = useRef(false);
	
	const started = useRef(false);
	
	const questionTime = useRef(false);
	const timer = useRef(0);
	const startTime = useRef(0);
	const answers = useRef(["", "", "", ""]);
	
	const waitingForCorrectOrNot = useRef(false);
	
	const correct = useRef(false);
	const wrong = useRef(false);
	
	const points = useRef(0);
	const totalPoints = useRef(0);
	const streak = useRef(0);
	const placement = useRef("");
	const distanceText = useRef("");
	
	const finished = useRef(false);
	
	const notKahoot = useRef(new Image());
	
	const license = useRef();
	
	function animate()
	{
		requestAnimationFrame(animate);
		context.current.fillStyle = "#FFFFFF";
		context.current.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
		if(questionTime.current)
		{
			context.current.fillStyle = "#E21B3C";
			context.current.fillRect(0,  0, canvasRef.current.width/2, canvasRef.current.height/2);
			context.current.fillStyle = "#FFA602";
			context.current.fillRect(0, canvasRef.current.height/2, canvasRef.current.width/2, canvasRef.current.height/2);
			context.current.fillStyle = "#1368CE";
			context.current.fillRect(canvasRef.current.width/2, 0, canvasRef.current.width/2, canvasRef.current.height/2);
			context.current.fillStyle = "#26890c";
			context.current.fillRect(canvasRef.current.width/2, canvasRef.current.height/2, canvasRef.current.width/2, canvasRef.current.height/2);
			setText(answers.current[0], answers.current[1], answers.current[2], answers.current[3], context.current);
		}
		else if(waitingForCorrectOrNot.current)
		{
			context.current.textAlign = "center";
			context.current.font = "50px Arial";
			context.current.fillStyle = "#000000";
			var x = 50;
			for(; context.current.measureText("Waiting for everyone to finish...").width>canvasRef.current.width*0.75; x--,  context.current.font = x + "px Arial"){} 
			context.current.fillText("Waiting for everyone to finish...", canvasRef.current.width/2, canvasRef.current.height/2-(x*0.7)/2);
		}
		else if(!started.current)
		{
			context.current.textAlign = "center";
			context.current.font = "50px Arial";
			context.current.fillStyle = "#000000";
			var x = 50;
			for(; context.current.measureText("Waiting for the game to start...").width>canvasRef.current.width*0.75; x--,  context.current.font = x + "px Arial"){} 
			context.current.fillText("Waiting for the game to start...", canvasRef.current.width/2, canvasRef.current.height/2-(x*0.7)/2);
		}
		else if(correct.current)
		{
			context.current.fillStyle = "green";
			context.current.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
			context.current.fillStyle = "#000000";
			context.current.textAlign = "center";
			context.current.font = "50px Arial";
			var x = 50;
			for(; context.current.measureText("+" + points.current).width>canvasRef.current.width*0.75; x--,  context.current.font = x + "px Arial"){} 
			context.current.fillText("+" + points.current, canvasRef.current.width/2, canvasRef.current.height/2-(x*0.7)/2-40);
			context.current.font = "50px Arial";
			x = 50;
			for(; context.current.measureText("Points: " + totalPoints.current).width>canvasRef.current.width*0.75; x--,  context.current.font = x + "px Arial"){} 
			context.current.fillText("Points: " + totalPoints.current, canvasRef.current.width/2, canvasRef.current.height/2-(x*0.7)/2+20);
			context.current.font = "25px Arial";
			x = 25;
			for(; context.current.measureText("You are in " + placement.current + " place with a streak of " + streak.current).width>canvasRef.current.width*0.75; x--,  context.current.font = x + "px Arial"){} 
			context.current.fillText("You are in " + placement.current + " place with a streak of " + streak.current, canvasRef.current.width/2, canvasRef.current.height/2-(x*0.7)/2+50);
			x = 25;
			for(; context.current.measureText(distanceText.current).width>canvasRef.current.width*0.75; x--,  context.current.font = x + "px Arial"){} 
			context.current.fillText(distanceText.current, canvasRef.current.width/2, canvasRef.current.height/2-(x*0.7)/2+78);
		}
		else if(wrong.current)
		{
			context.current.fillStyle = "red";
			context.current.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
			context.current.fillStyle = "#000000";
			context.current.textAlign = "center";
			context.current.font = "50px Arial";
			var x = 50;
			for(; context.current.measureText("+" + points.current).width>canvasRef.current.width*0.75; x--,  context.current.font = x + "px Arial"){} 
			context.current.fillText("+" + points.current, canvasRef.current.width/2, canvasRef.current.height/2-(x*0.7)/2-40);
			context.current.font = "50px Arial";
			x = 50;
			for(; context.current.measureText("Points: " + totalPoints.current).width>canvasRef.current.width*0.75; x--,  context.current.font = x + "px Arial"){} 
			context.current.fillText("Points: " + totalPoints.current, canvasRef.current.width/2, canvasRef.current.height/2-(x*0.7)/2+20);
			context.current.font = "25px Arial";
			x = 25;
			for(; context.current.measureText("You are in " + placement.current + " place").width>canvasRef.current.width*0.75; x--,  context.current.font = x + "px Arial"){} 
			context.current.fillText("You are in " + placement.current + " place", canvasRef.current.width/2, canvasRef.current.height/2-(x*0.7)/2+50);
				x = 25;
			for(; context.current.measureText(distanceText.current).width>canvasRef.current.width*0.75; x--,  context.current.font = x + "px Arial"){} 
			context.current.fillText(distanceText.current, canvasRef.current.width/2, canvasRef.current.height/2-(x*0.7)/2+78);
		}
		else if(finished.current)
		{
			context.current.fillStyle = "#000000";
			context.current.textAlign = "center";
			context.current.font = "100px Arial";
			x = 100;
			for(; context.current.measureText("You got " + placement.current + " place!").width>canvasRef.current.width*0.75; x--,  context.current.font = x + "px Arial"){} 
			context.current.fillText("You got " + placement.current + " place!", canvasRef.current.width/2, canvasRef.current.height/2+(x*0.7)/2-20);
		}
		else
		{
			context.current.textAlign = "center";
			context.current.font = "50px Arial";
			context.current.fillStyle = "#000000";
			var x = 50;
			for(; context.current.measureText("Waiting for the next question...").width>canvasRef.current.width*0.75; x--,  context.current.font = x + "px Arial"){} 
			context.current.fillText("Waiting for the next question...", canvasRef.current.width/2, canvasRef.current.height/2-(x*0.7)/2);
		}
		if(notKahoot.current)
		{
			middleDiv.current.style.left = (window.innerWidth-notKahoot.current.width)/2 + "px";
			middleDiv.current.style.top = (window.innerHeight-400)/2 + "px";
		}
	}
	
	function setText(a, b, c, d, context)
	{
		context.textAlign = "center";
		context.font = "50px Arial";
		context.strokeStyle = "#000000";
		context.fillStyle = "#FFFFFF";
		var x = 50;
		for(; context.measureText(a).width>canvasRef.current.width/2; x--,  context.font = x + "px Arial"){} 
		context.fillText(a, canvasRef.current.width/4, canvasRef.current.height/4+(x*0.7)/2);
		context.font = "50px Arial";
		for(x = 50; context.measureText(b).width>canvasRef.current.width/2; x--,  context.font = x + "px Arial"){} 
		context.fillText(b, canvasRef.current.width/4, canvasRef.current.height/4*3+(x*0.7)/2);
		context.font = "50px Arial";
		for(x = 50; context.measureText(c).width>canvasRef.current.width/2; x--,  context.font = x + "px Arial"){} 
		context.fillText(c, canvasRef.current.width/4*3, canvasRef.current.height/4+(x*0.7)/2);
		context.font = "50px Arial";
		for(x = 50; context.measureText(d).width>canvasRef.current.width/2; x--,  context.font = x + "px Arial"){} 
		context.fillText(d, canvasRef.current.width/4*3, canvasRef.current.height/4*3+(x*0.7)/2);
	}
	
	function joinGame()
	{
		console.log(socket.current.connected);
		if(socket.current.connected && !joining.current)
		{
			console.log("attempt");
			socket.current.emit("name", document.getElementById("name").value);
			socket.current.emit("join", document.getElementById("room").value.toUpperCase());
			joining.current = true;
		}
	}
	
	function joined()
	{
		middleDiv.current.remove();
		canvasRef.current.hidden = false;
		license.current.remove();
	}
	
	useEffect(() => {
		notKahoot.current.src = notkahoot;
		notKahoot.current.alt = "Not Kahoot logo";
		canvasRef.current = document.getElementById("game");
		canvasRef.current.parentElement.style.margin = 0;
		canvasRef.current.parentElement.style.padding = 0;
		document.body.style.overflow = "hidden";
		canvasRef.current.width = window.innerWidth;
		canvasRef.current.height = window.innerHeight;
		context.current = canvasRef.current.getContext("2d");
		window.addEventListener("resize", () => {
			canvasRef.current.width = window.innerWidth;
			canvasRef.current.height = window.innerHeight;
		});
		middleDiv.current = document.getElementById("middle");
		middleDiv.current.style.position = "absolute";
		socket.current = io.connect("wss://server.jamesgoo.de:9500", {transports: ["websocket"], forceNew: true, reconnection: false});
		socket.current.on("connect", () => {
			middleDiv.current.insertAdjacentHTML("beforeend", "<p/><span style='font-size:24px'>Name: </span><input type='text' autoComplete='off' style='font-size:24px' id='name'/><br/><span style='font-size:24px' >Game: </span><input type='text' autoComplete='off' style='font-size:24px' id='room'/><br/><button style='font-size:24px' id='join'>Enter</button>");
			if(notKahoot.current.width>window.innerWidth)
			{
				var w = notKahoot.current.width;
				notKahoot.current.width = window.innerWidth-10;
				notKahoot.current.height = notKahoot.current.height/w*notKahoot.current.width;
			}
			middleDiv.current.insertBefore(notKahoot.current, middleDiv.current.firstChild);
			document.getElementById("join").onclick = joinGame;
			animate();
		});
		socket.current.on("joined", x => {
			joined();
			joining.current = false;
		});
		socket.current.on("not_joined", x => {
			joining.current = false;
		});
		socket.current.on("start_game", x => {
			started.current = true;
		});
		socket.current.on("question", x => {
			answers.current = x[1];
			timer.current = x[2];
			questionTime.current = true;
			startTime.current = new Date().getTime();
			correct.current = false;
			wrong.current = false;
		});
		socket.current.on("end_question", x => {
			questionTime.current = false;
			waitingForCorrectOrNot.current = false;
			if(x[0]>0)
			{
				wrong.current = false;
				correct.current = true;
			}
			else
			{
				wrong.current = true;
				correct.current = false;
			}
			points.current = x[0];
			totalPoints.current = x[1];
			streak.current = x[2];
			placement.current = x[3];
			if(placement.current == "1st")
			{
				if(x[4] == "none")
				{
					distanceText.current = "";
				}
				else
				{
					distanceText.current =  x[4] + " points in front of " + x[5];
				}
			}
			else
			{
				distanceText.current =  x[4] + " points behind " + x[5];
			}
		});
		socket.current.on("waiting", x => {
			wrong.current = false;
			correct.current = false;
		});
		socket.current.on("finished", x => {
			wrong.current = false;
			correct.current = false;
			finished.current = true;
		});
		canvasRef.current.addEventListener("click", function (event) {
			if(questionTime.current)
			{
				var left = event.x<canvasRef.current.width/2
				var up = event.y<canvasRef.current.height/2;
				if(left)
				{
					if(up)
					{
						socket.current.emit("answer", [Math.floor(Math.pow(500, 0.9+(0.1-0.1*(((new Date().getTime()-startTime.current)/1000)/timer.current)))), 0]);
					}
					else
					{
						socket.current.emit("answer", [Math.floor(Math.pow(500, 0.9+(0.1-0.1*(((new Date().getTime()-startTime.current)/1000)/timer.current)))), 2]);
					}
				}
				else
				{
					if(up)
					{
						socket.current.emit("answer", [Math.floor(Math.pow(500, 0.9+(0.1-0.1*(((new Date().getTime()-startTime.current)/1000)/timer.current)))), 1]);
					}
					else
					{
						socket.current.emit("answer", [Math.floor(Math.pow(500, 0.9+(0.1-0.1*(((new Date().getTime()-startTime.current)/1000)/timer.current)))), 3]);
					}
				}
				questionTime.current = false;
				waitingForCorrectOrNot.current = true;
			}
		});
		return function cleanup() {
			socket.current.disconnect();
		}
	}, []);
	
	const style = {
		position: "absolute",
		overflow: "hidden"
	};
	
	
	return (
		<div style={{margin: 0,  overflow: "hidden"}}>
			<div id="middle"/>
			<canvas id="game" style={{style}} hidden={true}/>
			<a style={{"position": "absolute", "bottom": "2%", "transform": "translate(-50%, 0)"}} href="https://creativecommons.org/licenses/by-sa/4.0/" ref={license}>
				<img src="https://licensebuttons.net/l/by-sa/4.0/88x31.png"/>
			</a>
		</div>
	);
};

export default Play;