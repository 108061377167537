import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Game from "./pages/game";
import Create from "./pages/create";
import Play from "./pages/play";
import logo from './logo.svg';
import './App.css';

function App() {
  return (
      <div className="App">
	  <BrowserRouter>
		<Switch>
			<Route path="/host" exact component={Game} />
			<Route path="/create" exact component={Create} />
			<Route path="/play" exact component={Play} />
			<Route path="/" exact component={Play} />
		</Switch>
	  </BrowserRouter>
    </div>
  );
}

export default App;
